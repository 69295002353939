import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): scroll.js
 * --------------------------------------------------------------------------
 */

const Scroll = (($) => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'scroll'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'onma.scroll'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    duration : 400,
    offset   : 0,
    easing   : 'swing'
  }

  const DefaultType = {
    duration : 'number',
    offset   : 'number',
    easing   : 'string'
  }

  const Selector = {
    SCROLL : '[data-navigation="scroll"]',
    ANCHOR_LINK : 'a[href^="#"]'
  }

  const Event = {
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`,
    CLICK_DATA_API : `click${EVENT_KEY}${DATA_API_KEY}`
  }

  /*
  const ClassName = {
  }
  */

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Scroll {

    constructor(element, config) {
      this._element = element
      this._config  = this._getConfig(config)

      $(this._element).find(Selector.ANCHOR_LINK).on(Event.CLICK_DATA_API, (event) => {
        event.preventDefault();
        const href = $.attr(event.target, 'href')
        const $target = $(href)

        if ($target.length > 0) {
          $('html, body').animate({
            scrollTop: $target.offset().top - this._config.offset
          }, this._config.duration, this._config.easing)
        }
      })
    }


    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new Scroll(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.SCROLL).each(function () {
      const $scroll = $(this)
      Scroll._jQueryInterface.call($scroll, $scroll.data())
    })
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = Scroll._jQueryInterface
  $.fn[NAME].Constructor = Scroll
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Scroll._jQueryInterface
  }

  return Scroll

})($)

export default Scroll
