import $ from 'jquery'
import SimpleParallax from 'simple-parallax-js'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): parallax.js
 * --------------------------------------------------------------------------
 */

const Parallax = (($) => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'parallax'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'onma.parallax'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    orientation: 'up',
    scale: 1.2,
    overflow: false,
    delay: 0,
    transition: '',
    maxTransition: 0,
    customWrapper: ''
  }

  const DefaultType = {
    orientation: 'string',
    scale: 'number',
    overflow: 'bool',
    delay: 'number',
    transition: 'string',
    maxTransition: 'number',
    customWrapper: 'string'
  }

  const Selector = {
    PARALLAX : '[data-plugin="parallax"]'
  }

  const Event = {
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`
  }

  /*
  const ClassName = {
  }
  */

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Parallax {

    constructor(element, config) {
      this._element = element
      this._config  = this._getConfig(config)

      this._parallax = new SimpleParallax(this._element, {
        orientation: this._config.orientation,
        scale: this._config.scale,
        overflow: this._config.overflow,
        delay: this._config.delay,
        transition: this._config.transition,
        maxTransition: this._config.maxTransition,
        customWrapper: this._config.customWrapper
      });
    }


    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data = $element.data(DATA_KEY)

        if (!data) {
          data = new Parallax(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.PARALLAX).each(function () {
      const $parallax = $(this)
      Parallax._jQueryInterface.call($parallax, $parallax.data())
    })
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = Parallax._jQueryInterface
  $.fn[NAME].Constructor = Parallax
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Parallax._jQueryInterface
  }

  return Parallax

})($)

export default Parallax
