import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): interactive-form.js
 * --------------------------------------------------------------------------
 */

const InteractiveForm = (($) => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'interactive-form'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'onma.interactive-form'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    useSessionStorage: false,
    configUrl: 'config.json',
    loadStartSlide: true,
    evaluationUrl: 'form.php',
    imagePathPrefix: '',
    mobilePopup: false
  }

  const DefaultType = {
    useSessionStorage: 'boolean',
    configUrl: 'string',
    loadStartSlide: 'boolean',
    evaluationUrl: 'string',
    imagePathPrefix: 'string',
    mobilePopup: 'boolean'
  }


  const Selector = {
    BODY             : 'body',
    INTERACTIVE_FORM : '[data-plugin="interactive-form"]',
    SLIDE_WRAPPER    : '.slide-wrapper',
    SLIDE_CONTAINER  : '.slide-container',
    SLIDE            : '.slide',
    SLIDE_BACK       : '.slide-back',
    SLIDE_TO         : '[data-slide-to]',
    FIELDS           : 'input,select,textarea',
    INPUT            : 'input',
    TEXTAREA         : 'textarea',
    INPUT_FILE       : 'input[type="file"]',
    INPUT_RANGE      : 'input[type="range"]',
    INPUT_TEXT       : 'input[type="text"]',
    INPUT_CHECKBOX   : 'input[type="checkbox"]',
    SUBMIT           : 'button.submit',
    BUTTONS          : '[data-slide-to],button.submit'
  }

  const Event = {
    LOAD_DATA_API  : `load${EVENT_KEY}${DATA_API_KEY}`,
    HISTORY_STATE  : `popstate${EVENT_KEY}${DATA_API_KEY}`,
    TRANSITION_END : `transitionend${EVENT_KEY}${DATA_API_KEY}`,
    CHANGE         : `change${EVENT_KEY}${DATA_API_KEY}`,
    CLICK          : `click${EVENT_KEY}${DATA_API_KEY}`,
    KEYUP          : `keyup${EVENT_KEY}${DATA_API_KEY}`,
    MOUSEMOVE      : `mousemove${EVENT_KEY}${DATA_API_KEY}`
  }

  const Direction = {
    LEFT: 1,
    RIGHT: 2
  }

  const ClassName = {
    MOBILE_POPUP: 'ix-form-mobile-popup'
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class InteractiveForm {

    constructor(element, config) {
      if (config.mobilePopup && this._isDeviceMobile() && !$(element).parent().hasClass(ClassName.MOBILE_POPUP)) {
        const $mobilePopupElement = $(element).clone()
        const $mobilePopupWrapper = $(`<div class="${ClassName.MOBILE_POPUP}" />`)
        const $mobilePopupCloseButton = $('<div class="close">X</div>')
        $mobilePopupWrapper.append($mobilePopupCloseButton)
        $mobilePopupCloseButton.on('click', () => {
          $mobilePopupWrapper.remove()
        })
        $mobilePopupWrapper.prepend($mobilePopupElement)
        InteractiveForm._jQueryInterface.call($mobilePopupElement, $mobilePopupElement.data())
        $(Selector.BODY).prepend($mobilePopupWrapper)
      }
      this._initialize(element, config)
    }

    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private

    _initialize(element, config) {
      this._id       = this._uniqueID();
      this._element  = element
      this._$element = $(element)
      this._$parent  = this._$element.parent()
      this._config   = this._getConfig(config)
      this._formData = new FormData();
      this._storage  = this._getStorageObject()
      this._history  = this._getHistoryObject()
      this._slides   = {};
      this._isMobile = this._$parent.hasClass(ClassName.MOBILE_POPUP)

      this._$wrapper   = this._$element.find(Selector.SLIDE_WRAPPER);
      this._$container = this._$wrapper.find(Selector.SLIDE_CONTAINER);
      this._$slides    = this._$container.find(Selector.SLIDE);

      this._isSliding  = false

      if (this._storage === undefined) {
        throw new Error('InteractiveForm only supports session or local storage but these are unavailable.')
      }

      if (this._history !== undefined) {
        $(window).on(Event.HISTORY_STATE, (event) => {

          const id  = event.originalEvent.state.id

          if (this._id !== id) {
            event.preventDefault()
            return false
          }

          if (this._isSliding) {
            event.preventDefault()
            return false
          }

          const slideName  = event.originalEvent.state.slideName
          const slideIndex = event.originalEvent.state.slideIndex

          let direction = Direction.LEFT

          if (this._getCurrentSlideIndex() > slideIndex) {
            direction = Direction.RIGHT
          }

          this._slideTo(slideName, direction, false)

          return true
        })
      }

      // always clear storage at startup at the moment
      this._storage.clear()

      // binding events on default slide if available
      this._bindingSlideEvents(this._$slides)

      // load config file
      this._loadConfigFile((data) => {
        this._data = data
        if (this._config.loadStartSlide) {
          // render first slide
          const $start = this._buildSlideHtml('start')
          this._$container.append($start)
        }
        // if mobile version set opacity
        if (this._isMobile) {
          this._$parent.css('opacity', 1)
        }
      })

      // set the current slide at startup always to "start"
      this._setCurrentSlideName('start')
      this._setCurrentSlideIndex(0)
      // initialize the first state for start slide
      this._history.pushState({
        id: this._id,
        slideName: 'start',
        slideIndex: 0
      }, 'start')
    }

    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _getStorageObject() {
      if (window.localStorage !== undefined && !this._config.useSessionStorage) {
        return window.localStorage
      } else if (window.sessionStorage !== undefined) {
        return window.sessionStorage
      }

      return undefined
    }

    _getHistoryObject() {
      if (window.history) {
        return window.history
      }
      return undefined
    }

    // functions to load data and build data

    _buildSlideHtml(slideName) {
      // if we've already a cached slideHtml for this slideName, just bind
      // events and return
      if (this._slides[slideName] !== undefined) {
        return this._bindingSlideEvents(
          this._slides[slideName]
        );
      }
      if (!this._hasSlideConfig(slideName)) {
        throw new Error(`InteractiveForm failed to find config for slide: ${slideName}`)
      }
      const slideConfig = this._getSlideConfig(slideName)
      const slideType   = slideConfig.type
      const slideClass   = slideConfig.class
      const $slideHtml = $(`<div class="slide ${slideClass}" data-name="${slideName}" data-type="${slideType}"></div>`);

      if (slideConfig.buttonBack) {
        // when the slideName is not start we need to add an back button
        const $slideBack = $('<div class="slide-back" />');
        $slideHtml.append($slideBack);
      }

      let $subheadline = $('<h3 class="text-center">&nbsp;</h3>')
      if (slideConfig.subheadline) {
        $subheadline = $(`<h3 class="text-center">${slideConfig.subheadline}</h3>`)
      }
      $slideHtml.append($subheadline)

      let $headline = $('<h2 class="text-center">&nbsp;</h2>')
      if (slideConfig.headline) {
        $headline = $(`<h2 class="text-center">${slideConfig.headline}</h2>`)
      }
      $slideHtml.append($headline)

      if (slideConfig.percent) {
        $slideHtml.append(this._buildProgressBarHtml(slideConfig.percent))
      }

      switch (slideType) {
        default:
          throw new Error(`InteractiveForm invalid type "${slideType}" for slide "${slideName}" found.`)
        case 'box':
          $slideHtml.append(
            this._buildSlideBoxHtml(slideConfig)
          )
          break
        case 'select':
          $slideHtml.append(
            this._buildSlideSelectHtml(slideConfig)
          )
          break
        case 'input':
          $slideHtml.append(
            this._buildSlideInputHtml(slideConfig)
          )
          break
        case 'media':
          $slideHtml.append(
            this._buildSlideMediaHtml(slideConfig)
          )
          break
        case 'textarea':
          $slideHtml.append(
            this._buildSlideTextareaHtml(slideConfig)
          )
          break
        case 'checkbox':
          $slideHtml.append(
            this._buildSlideCheckboxHtml(slideConfig)
          )
          break
        case 'dropdown':
          $slideHtml.append(
            this._buildSlideDropdownHtml(slideConfig)
          )
          break
        case 'form':
          $slideHtml.append(
            this._buildSlideFormHtml()
          )
          break
        case 'message':
          $slideHtml.append(
            this._buildSlideMessageHtml(slideConfig)
          )
          break
      }

      if (slideConfig.footer) {
        /*
        const $footer = $(
          '<div class="slide-footer">' +
            '<div class="row">' +
              '<div class="col-12 col-md-4">' +
                '<div class="icon-check"></div>Hausverkauf<br />zum Bestpreis' +
              '</div>' +
              '<div class="col-12 col-md-4">' +
                '<div class="icon-check"></div>Beratung durch<br />erfahrende Makler' +
              '</div>' +
              '<div class="col-12 col-md-4">' +
                '<div class="icon-check"></div>Schnell und<br />unkompliziert' +
              '</div>' +
            '</div>' +
          '</div>'
        )
        */
        const $footer = $(
          '<div class="slide-footer">' +
            '<img src="images/lp/logos_desktop.png" class="img-fluid d-none d-md-block mx-auto" />' +
            '<img src="images/lp/logos_mobile.png" class="img-fluid d-block d-md-none" />' +
          '</div>'
        )
        $slideHtml.append($footer)
      }

      if (this._isSlideValid($slideHtml)) {
        $slideHtml.find(Selector.BUTTONS).removeAttr('disabled')
      } else {
        $slideHtml.find(Selector.BUTTONS).attr('disabled', 'disabled')
      }

      // save generated html for this slide for next time and bind events
      this._slides[slideName] = $slideHtml
      return this._bindingSlideEvents($slideHtml)
    }

    _buildSlideBoxHtml(slideConfig) {
      // const grids = 12;
      const $container = $('<div />')
      if (slideConfig.buttons && slideConfig.buttons.length > 0) {
        let $buttons = $('<div class="row buttons d-none d-md-flex justify-content-md-center" />')
        const $buttonsSmall = $('<div class="row buttons d-flex d-md-none" />')
        // const switchToOtherLayoutCount = 5
        // const maxColumnsBefore5 = 3
        // const maxColumnsAfter5 = 3
        const buttonCount = slideConfig.buttons.length
        // let columnValue = Math.floor(grids / buttonCount)
        const columnValue = 3
        const buttonNewRowApply = 4
        const buttonRowMaxCount = 3

        $.each(slideConfig.buttons, (i, button) => {
          /*
          if (buttonCount >= switchToOtherLayoutCount) {
            if (columnValue < maxColumnsAfter5) {
              columnValue = maxColumnsAfter5
            }
          } else if (columnValue < maxColumnsBefore5) {
            columnValue = maxColumnsBefore5
          }
          */

          // const $button = $(`<div class="col-md-${columnValue} col-sm-6 col-6 button" data-slide-to="${button.link}" data-value="${button.name}" />`)
          const $button = $(`<div class="col-md-${columnValue} col-sm-6 col-6 button" data-slide-to="${button.link}" data-value="${button.name}" />`)
          const $buttonInner = $('<div class="button-inner" />')
          if (button.icon) {
            $buttonInner.append(`<img src="${this._config.imagePathPrefix}${button.icon}" class="img-fluid" />`)
          }
          if (button.name) {
            $buttonInner.append(`<h4>${button.name}</h4>`)
          }
          $button.append($buttonInner)
          if (buttonCount > buttonNewRowApply && i % buttonRowMaxCount === 0 && i > 0) {
            $container.append($buttons)
            $buttons = $('<div class="row buttons d-none d-md-flex justify-content-md-center" />')
          }
          $buttons.append($button)
          $buttonsSmall.append($button.clone())
        })

        $container.append($buttons)
        $container.append($buttonsSmall)
      }

      if (slideConfig.button && slideConfig.button.name && slideConfig.button.link) {
        $container.append(`<button class="additional-button btn btn-primary w-100" data-slide-to="${slideConfig.button.link}">${slideConfig.button.name}</button>`)
      }

      return $container
    }

    _buildSlideSelectHtml(slideConfig) {
      const $container = $('<div />')

      let $row = $('<div class="row" />')

      let $largeColumn = $('<div class="col-sm-8 col-12 mb-5 mb-sm-0 align-self-center" />')
      let $smallColumn = $('<div class="col-sm-4 col-12" />')

      $row.append(
        $largeColumn.clone().html(
          this._buildSlideSelectRangeHtml(
            slideConfig.text,
            slideConfig.range.from,
            slideConfig.range.to,
            slideConfig.default
          )
        )
      )

      $row.append(
        $smallColumn.clone().html(
          `<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`
        )
      )

      $container.append($row)

      $row = $('<div class="row" />')

      $largeColumn = $('<div class="col-sm-8 col-12 mb-3 mb-sm-0" />')
      $smallColumn = $('<div class="col-sm-4 col-12 align-self-end" />')

      $row.append(
        $largeColumn.clone().html(
          this._buildSlideSelectInputHtml(
            slideConfig.default,
            slideConfig.unit,
            slideConfig.range.from,
            slideConfig.range.to
          )
        )
      )

      $row.append(
        $smallColumn.clone().html(
          `<button class="btn btn-primary" disabled data-slide-to="${slideConfig.link}">Weiter</button>`
        )
      )

      $container.append($row)

      return $container
    }

    _buildSlideSelectRangeHtml(text, min, max, value) {
      const $container = $('<div class="form-group" />')

      if (text) {
        const $label = $(`<label>${text}</label>`)
        $container.append($label)
      }

      const $input = $(`<input type="range" class="select-range" min="${min}" max="${max}" value="${value}">`)
      $container.append($input)

      this._setSliderRangeBackground($input)

      return $container
    }

    _buildSlideSelectInputHtml(value, unit, min, max) {
      const $container = $('<div class="form-group" />')

      const $label = $('<label>Manuell eintippen:</label>')
      $container.append($label)

      const $inputGroup = $('<div class="input-group" />')

      const $input = $(`<input type="text" required class="form-control" min="${min}" max="${max}" value="${value}">`)
      $inputGroup.append($input)

      if (unit) {
        const $inputUnit = $(`<div class="input-group-append"><span class="input-group-text">${unit}</span></div>`)
        $inputGroup.append($inputUnit)
      }
      $container.append($inputGroup)
      return $container
    }

    _buildSlideInputHtml(slideConfig) {
      const $container = $('<div />')

      const $row = $('<div class="row align-items-center" />')

      if (slideConfig.icon) {
        const $iconColumn = $('<div class="col-lg-3 col-md-6 col-12 d-none d-md-block" />')
        const $icon = $(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`)

        $iconColumn.append($icon)
        $row.append($iconColumn)
      }

      const $inputColumn = $('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

      if (slideConfig.input) {
        const inputConfig = slideConfig.input

        if (inputConfig.title) {
          $inputColumn.append(`<h4>${inputConfig.title}</h4>`)
        }

        const $input = $(`<input type="text" required class="form-control mb-3" value="" placeholder="${inputConfig.placeholder}">`)

        if (inputConfig.min) {
          $input.attr('min', inputConfig.min)
        }

        if (inputConfig.max) {
          $input.attr('max', inputConfig.max)
        }

        if (inputConfig.pattern) {
          $input.attr('pattern', inputConfig.pattern)
        }

        $inputColumn.append($input)
      }
      $inputColumn.append(`<button class="btn btn-primary w-100" disabled data-slide-to="${slideConfig.link}">Weiter</button>`)
      $row.append($inputColumn)
      $container.append($row)
      return $container
    }

    _buildSlideMediaHtml(slideConfig) {
      const $container = $('<div />')

      if (slideConfig.title) {
        $container.append(`<h4>${slideConfig.title}</h4>`)
      }
      if (slideConfig.infotext) {
        $container.append(`<h5 class="text-center text-secondary">${slideConfig.infotext}</h5>`)
      }

      const $row = $('<div class="row align-items-center" />')

      const $imageColumn1 = $('<div class="col-lg-6 col-md-6 col-12 text-center" />')

      const $image1 = $(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel1.png">`)

      $imageColumn1.append($image1)

      const $inputColumn1 = $('<div class="col-lg-6 col-md-6 col-12" />')

      const $input1 = $('<input type="file" class="form-control mb-3 w-lg-75" value="">')

      $inputColumn1.append($input1)

      const $imageColumn2 = $('<div class="col-lg-6 col-md-6 col-12 text-center" />')

      const $image2 = $(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel2.png">`)

      $imageColumn2.append($image2)

      const $inputColumn2 = $('<div class="col-lg-6 col-md-6 col-12" />')

      const $input2 = $('<input type="file" class="form-control mb-3 w-lg-75" value="">')

      $inputColumn2.append($input2)

      const $imageColumn3 = $('<div class="col-lg-6 col-md-6 col-12 text-center" />')

      const $image3 = $(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel3.png">`)

      $imageColumn3.append($image3)

      const $inputColumn3 = $('<div class="col-lg-6 col-md-6 col-12" />')

      const $input3 = $('<input type="file" class="form-control mb-3 w-lg-75" value="">')

      $inputColumn3.append($input3)

      const $imageColumn4 = $('<div class="col-lg-6 col-md-6 col-12 text-center" />')

      const $image4 = $(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel4.png">`)

      $imageColumn4.append($image4)

      const $inputColumn4 = $('<div class="col-lg-6 col-md-6 col-12" />')

      const $input4 = $('<input type="file" class="form-control mb-3 w-lg-75" value="">')

      $inputColumn4.append($input4)

      const $imageColumn5 = $('<div class="col-lg-6 col-md-6 col-12 text-center" />')

      const $image5 = $(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel5.png">`)

      $imageColumn5.append($image5)

      const $inputColumn5 = $('<div class="col-lg-6 col-md-6 col-12" />')

      const $input5 = $('<input type="file" class="form-control mb-3 w-lg-75" value="">')

      $inputColumn5.append($input5)

      const $buttonColumn = $('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

      $buttonColumn.append(`<button class="btn btn-primary w-100" disabled data-slide-to="${slideConfig.link}">Weiter</button>`)

      $row.append($imageColumn1)
      $row.append($inputColumn1)
      $row.append($imageColumn2)
      $row.append($inputColumn2)
      $row.append($imageColumn3)
      $row.append($inputColumn3)
      $row.append($imageColumn4)
      $row.append($inputColumn4)
      $row.append($imageColumn5)
      $row.append($inputColumn5)
      $row.append($buttonColumn)

      $container.append($row)

      return $container
    }

    _buildSlideTextareaHtml(slideConfig) {
      const $container = $('<div />')

      const $row = $('<div class="row align-items-center" />')

      if (slideConfig.icon) {
        const $iconColumn = $('<div class="col-lg-3 col-md-6 col-12 d-none d-md-block" />')
        const $icon = $(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`)

        $iconColumn.append($icon)
        $row.append($iconColumn)
      }

      const $inputColumn = $('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

      if (slideConfig.textarea) {
        const inputConfig = slideConfig.textarea

        if (inputConfig.title) {
          $inputColumn.append(`<h4>${inputConfig.title}</h4>`)
        }

        // const $input = $(`<input type="text" required class="form-control mb-3" value="" placeholder="${inputConfig.placeholder}">`)
        const $input = $(`<textarea required class="form-control mb-3" placeholder="${inputConfig.placeholder}"></textarea>`)

        if (inputConfig.rows) {
          $input.attr('rows', inputConfig.rows)
        }

        if (inputConfig.maxlength) {
          $input.attr('maxlength', inputConfig.maxlength)
        }

        if (inputConfig.minlength) {
          $input.attr('minlength', inputConfig.minlength)
        }

        if (inputConfig.pattern) {
          $input.attr('pattern', inputConfig.pattern)
        }

        $inputColumn.append($input)
      }
      $inputColumn.append(`<button class="btn btn-primary w-100" disabled data-slide-to="${slideConfig.link}">Weiter</button>`)
      $row.append($inputColumn)
      $container.append($row)
      return $container
    }

    _buildSlideCheckboxHtml(slideConfig) {
      const $container = $('<div />')

      const $row = $('<div class="row align-items-center" />')

      if (slideConfig.icon) {
        const $iconColumn = $('<div class="col-lg-3 col-md-6 col-12 d-none d-md-block" />')
        const $icon = $(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`)

        $iconColumn.append($icon)
        $row.append($iconColumn)
      }

      const $inputColumn = $('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

      if (slideConfig.checkbox) {
        const inputConfig = slideConfig.checkbox

        if (inputConfig.title) {
          $inputColumn.append(`<h4>${inputConfig.title}</h4>`)
        }

        const $input = $('<div class="checkbox-container mb-4"></div>')


        if (inputConfig.options) {
          $.each(inputConfig.options, (value, label) => {
            $input.append(`<div class="my-2"><input class="mr-2" type="checkbox" id="${value}" value="${label}"><label class="d-inline" for="${value}">${label}</label></div>`)
          })
        }

        $inputColumn.append($input)
      }
      $inputColumn.append(`<button class="btn btn-primary w-100" disabled data-slide-to="${slideConfig.link}">Weiter</button>`)
      $row.append($inputColumn)
      $container.append($row)
      return $container
    }

    _buildSlideDropdownHtml(slideConfig) {
      const $container = $('<div />')

      const $row = $('<div class="row align-items-center" />')

      if (slideConfig.icon) {
        const $iconColumn = $('<div class="col-lg-3 col-md-6 col-12 d-none d-md-block" />')
        const $icon = $(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`)

        $iconColumn.append($icon)
        $row.append($iconColumn)
      }

      const $inputColumn = $('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

      if (slideConfig.dropdown) {
        const inputConfig = slideConfig.dropdown

        if (inputConfig.title) {
          $inputColumn.append(`<h4>${inputConfig.title}</h4>`)
        }

        // const $input = $(`<input type="text" required class="form-control mb-3" value="" placeholder="${inputConfig.placeholder}">`)
        const $input = $('<select size="1" required class="form-control mb-3"></select>')

        if (inputConfig.options) {
          $.each(inputConfig.options, (value, label) => {
            $input.append(`<option value="${value}">${label}</option>`)
          })
        }

        $inputColumn.append($input)
      }
      $inputColumn.append(`<button class="btn btn-primary w-100" disabled data-slide-to="${slideConfig.link}">Weiter</button>`)
      $row.append($inputColumn)
      $container.append($row)
      return $container
    }

    _buildSlideFormHtml() {
      const $container = $('<div class="form-content" />')
      const $row = $('<div class="row" />')
      const $leftColumn = $('<div class="col-lg-6 col-12" />')

/*
      $leftColumn.append(
        this._wrapWithFormGroup('<select class="form-control" required name="salutation" size="1"><option value="Herr">Herr</option><option value="Frau">Frau</option></select>')
      )
*/

      const $formGroup = $('<div class="form-group" />')

      const $radioButton1 = $('<div class="custom-control custom-radio custom-control-inline" />')

      $radioButton1.append(
        '<input type="radio" class="custom-control-input" required id="salutation2" name="salutation" value="Frau"><label class="custom-control-label" for="salutation2">Frau</label>'
      )

      $formGroup.append($radioButton1)

      const $radioButton2 = $('<div class="custom-control custom-radio custom-control-inline" />')

      $radioButton2.append(
        '<input type="radio" class="custom-control-input" required id="salutation1" name="salutation" value="Herr"><label class="custom-control-label" for="salutation1">Herr</label>'
      )

      $formGroup.append($radioButton2)

      $leftColumn.append($formGroup)

      $leftColumn.append(
        this._wrapWithFormGroup('<input type="text" required class="form-control" placeholder="Vorname*" name="firstName" />')
      )

      $leftColumn.append(
        this._wrapWithFormGroup('<input type="text" required class="form-control" placeholder="Nachname*" name="lastName" />')
      )

      $leftColumn.append(
        this._wrapWithFormGroup(
          '<select size="5" class="form-control selectpicker" name="weekdays" title="Für Termin Wochentage auswählen (Mehrfachauswahl möglich)" multiple>' +
            '<option value="Mo">Montag</option>' +
            '<option value="Di">Dienstag</option>' +
            '<option value="Mi">Mittwoch</option>' +
            '<option value="Do">Donnerstag</option>' +
            '<option value="Fr">Freitag</option>' +
          '</select>'
        )
      )

      $row.append($leftColumn)

      const $rightColumn = $('<div class="col-lg-6 col-12" />')

      $rightColumn.append(
        $('<div class="form-group d-none d-lg-block" />').append('&nbsp;')
      )

      $rightColumn.append(
        this._wrapWithFormGroup('<input type="email" required class="form-control" placeholder="E-Mail-Adresse*" name="email" />')
      )

      $rightColumn.append(
        this._wrapWithFormGroup('<input type="text" class="form-control" placeholder="Telefonnummer" name="phone" />')
      )

      $rightColumn.append(
        this._wrapWithFormGroup(
          '<select size="5" class="form-control selectpicker" name="times" multiple title="Für Termin Zeitraum auswählen (Mehrfachauswahl möglich)">' +
            '<option value="Vormittags">Vormittags</option>' +
            '<option value="Nachmittags">Nachmittags</option>' +
          '</select>'
        )
      )

      $row.append($rightColumn)

      $container.append($row)

      // new row for submit button

      const $row2 = $('<div class="row" />')
      const $leftColumn2 = $('<div class="col-lg-6 col-12" />')

      $leftColumn2.append(
        this._wrapWithFormGroup(
          '<div>' +
            '<small class="text-secondary">Mit dem Absenden der Antworten erklären Sie sich mit unseren Datenschutzbestimmungen und AGB einverstanden.</small>' +
          '</div>'
        )
      )
      $row2.append($leftColumn2)

      const $rightColumn2 = $('<div class="col-lg-6 col-12" />')
      $rightColumn2.append('<button class="btn btn-primary w-100 submit" disabled>Senden</button>')

      $row2.append($rightColumn2)

      $container.append($row2)

      if ($.fn.selectpicker) {
        $container.find('.selectpicker').selectpicker();
      }

      return $container
    }

    _buildSlideMessageHtml(slideConfig) {
      const $container = $('<div />')
      $container.append(`<p>${slideConfig.message}</p>`)
      return $container
    }

    _buildProgressBarHtml(progress) {
      const $container = $('<div class="progress" />')
      const $bar = $(`<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="${progress}" aria-valuemin="0" aria-valuemax="100" style="width: ${progress}%" />`)
      $container.append($bar)
      return $container
    }

    _wrapWithFormGroup($input) {
      return $('<div class="form-group" />').append($input)
    }

    _bindingSlideEvents($slides) {
      return $slides.each((i, slide) => {
        const $slide = $(slide)
        $slide.find(Selector.SLIDE_TO).on(Event.CLICK, (event) => {
          event.preventDefault()

          const $target = $(event.currentTarget)
          const slideName = $target.data('slide-to')

          // const $slideBefore = this._getCurrentSlide()
          const slideNameBefore  = this._getCurrentSlideName()
          const slideTypeBefore  = this._getCurrentSlideType()

          let slideValueBefore   = ''

          if (slideTypeBefore === 'box') {
            slideValueBefore = $target.data('value')
          } else {
            slideValueBefore = this._getCurrentSlideValue()
          }

          this._slideTo(slideName, Direction.LEFT, true, () => {
            this._setValue(slideNameBefore, slideValueBefore)
          })

          return false
        })

        $slide.find(Selector.SLIDE_BACK).on(Event.CLICK, (event) => {
          event.preventDefault()
          history.back()
          return false
        })

        $slide.find(Selector.INPUT_RANGE).on(Event.CHANGE, (event) => {
          event.preventDefault()
          const $target = $(event.target)
          $slide.find(Selector.INPUT_TEXT).val($target.val())
          return false
        }).on(Event.MOUSEMOVE, (event) => {
          const $target = $(event.target)
          this._setSliderRangeBackground($target)
        })

        $slide.find(Selector.INPUT_TEXT).on(Event.KEYUP, (event) => {
          event.preventDefault()
          const $target = $(event.target)
          const $range = $slide.find(Selector.INPUT_RANGE)
          $range.val($target.val())
          this._setSliderRangeBackground($range)
          return false
        })

        $slide.find(Selector.FIELDS).on(`${Event.KEYUP} ${Event.CHANGE}`, (event) => {
          event.preventDefault()
          if (this._isSlideValid($slide, true)) {
            $slide.find(Selector.BUTTONS).removeAttr('disabled')
          } else {
            $slide.find(Selector.BUTTONS).attr('disabled', 'disabled')
          }
          return false
        })

        $slide.find(Selector.SUBMIT).on(Event.CLICK, (event) => {
          event.preventDefault()
          $(slide).find(Selector.FIELDS).each((i, field) => {
            const $field     = $(field)
            const fieldName  = $field.attr('name')
            const fieldValue = $field.val()
            this._formData.set(`formValues[${fieldName}]`, fieldValue)
          })
          $.ajax(this._config.evaluationUrl, {
            dataType: 'json',
            method: 'post',
            cache: false,
            contentType: false,
            processData: false,
            data: this._formData,
            error: () => {
              this._slideTo('error')
            },
            success: () => {
              this._slideTo('success', null, false, () => {
                //  clear storage after success
                this._formData = new FormData();
                this._storage.clear()
              })
            }
          })
          return false
        })
      })
    }

    // functions to slide

    _slideTo(slideName, direction, history, callback) {
      if (this._isSliding) {
        return
      }
      this._isSliding = true
      const $slide = this._buildSlideHtml(slideName)
      const slideIndex = this._getCurrentSlideIndex()
      const afterSlideCallback = () => {
        if (history === true) {
          this._history.pushState({
            id: this._id,
            slideName,
            slideIndex
          }, slideName)
        }
        if (direction === Direction.LEFT) {
          this._incrementCurrentSlideIndex()
        } else {
          this._decreaseCurrentSlideIndex()
        }
        this._setCurrentSlideName(slideName)
        this._isSliding = false
        this._callback(callback)
      }
      switch (direction) {
        default:
        case Direction.LEFT:
          this._slideToLeft($slide, afterSlideCallback)
          break
        case Direction.RIGHT:
          this._slideToRight($slide, afterSlideCallback)
          break
      }
    }

    _slideToLeft($slide, callback) {
      this._$container.append($slide)
      this._$container.on(Event.TRANSITION_END, (e) => {
        if (e.originalEvent.propertyName !== 'left') {
          return
        }
        this._$container.off(e)
        this._$container.removeClass('slide-container--transition')
        this._$container.children().first().remove()
        this._$container.css('left', '0')
        this._callback(callback)
      }).addClass('slide-container--transition').css('left', '-100%')
    }

    _slideToRight($slide, callback) {
      const timeout = 100
      this._$container.prepend($slide)
      this._$container.css('left', '-100%')
      // little timeout after setting left to -100%
      setTimeout(() => {
        this._$container.on(Event.TRANSITION_END, (e) => {
          if (e.originalEvent.propertyName !== 'left') {
            return
          }
          this._$container.off(e)
          this._$container.removeClass('slide-container--transition')
          this._$container.children().last().remove()
          this._callback(callback)
        }).addClass('slide-container--transition').css('left', '0px')
      }, timeout)
    }

    // functions read and write session data

    _getCurrentSlideName() {
      return this._storage.getItem(`currentSlideName${this._id}`)
    }

    _setCurrentSlideName(currentSlideName) {
      this._storage.setItem(`currentSlideName${this._id}`, currentSlideName)
    }

    _getCurrentSlideIndex() {
      return this._storage.getItem(`currentSlideIndex${this._id}`)
    }

    _setCurrentSlideIndex(currentSlideIndex) {
      this._storage.setItem(`currentSlideIndex${this._id}`, currentSlideIndex)
    }

    _incrementCurrentSlideIndex() {
      const currentSlideIndex = parseInt(this._getCurrentSlideIndex(), 10);
      this._setCurrentSlideIndex(currentSlideIndex + 1)
    }

    _decreaseCurrentSlideIndex() {
      const currentSlideIndex = parseInt(this._getCurrentSlideIndex(), 10);
      this._setCurrentSlideIndex(currentSlideIndex - 1)
    }

    _isSlideValid($slide, mark) {
      let isValid = true
      switch ($slide.data('type')) {
        case 'checkbox':
          isValid = false;
          $slide.find(Selector.INPUT_CHECKBOX).each((i, input) => {
            if ($(input).is(':checked')) {
              isValid = true;
              return;
            }
          });
          break;
        default:
          $slide.find(Selector.FIELDS).each((i, input) => {
            if (!input.checkValidity()) {
              isValid = false;
              input.reportValidity()
              if (mark === true) {
                $(input).addClass('is-invalid')
              }
            } else {
              $(input).removeClass('is-invalid')
            }
          })
          break;
      }
      return isValid
    }

    _isCurrentSlideValid(mark) {
      const $slide = this._getCurrentSlide()
      return this._isSlideValid($slide, mark)
    }

    _getCurrentSlideValue() {
      const $slide = this._getCurrentSlide()
      let value = null
      switch ($slide.data('type')) {
        case 'select':
          value = $slide.find(Selector.INPUT_RANGE).val()
          break
        case 'input':
          value = $slide.find(Selector.INPUT).val()
          break
        case 'checkbox':
          value = [];
          $slide.find(Selector.INPUT_CHECKBOX).each((i, input) => {
            if ($(input).is(':checked')) {
              value.push($(input).val());
            }
          })
          break
        case 'textarea':
          value = $slide.find(Selector.TEXTAREA).val()
          break
        case 'media':
          value = [];
          $slide.find(Selector.INPUT_FILE).each((i, input) => {
            if ($(input)[0].files[0]) {
              value.push($(input)[0].files[0]);
            }
          })
          break
        case 'form':
          // value = $slide.find(Selector.INPUT).val()
          break
        default:
          break
      }
      return value
    }

    _getCurrentSlideType() {
      const $slide = this._getCurrentSlide()
      return $slide.data('type')
    }

    _getCurrentSlide() {
      return this._$container.find(Selector.SLIDE).first()
    }

    _getValue(name) {
      this._formData.get(name)
    }

    _setValue(name, value) {
      if (!$.isArray(value)) {
        this._formData.set(name, value)
      } else {
        this._formData.delete(`${name}[]`)
        $.each(value, (index, value) => {
          this._formData.append(`${name}[]`, value)
        })
      }
    }

    // standard functions

    _setSliderRangeBackground($rangeSlider) {
      const percentMultiplier = 100;
      const value = ($rangeSlider.val() - $rangeSlider.attr('min')) / ($rangeSlider.attr('max') - $rangeSlider.attr('min'));
      const percent = value * percentMultiplier;

      $rangeSlider.css(
        'background-image',
        `linear-gradient(to left bottom, #f7a71d 0%, #f7a71d ${percent}%, #b4b4b4 ${percent}%, #b4b4b4 100%)`
      )

      $rangeSlider.css(
        'background-image',
        `-webkit-gradient(linear, left top, right top, color-stop(${percent}%, #f7a71d), color-stop(${percent}%, #b4b4b4))`
      );
    }
    // functions to load config

    _loadConfigFile(callback) {
      $.ajax(this._config.configUrl, {
        dataType: 'json',
        error: () => {
          throw new Error(`InteractiveForm failed to download config file: ${this._config.configUrl}`)
        },
        success: (data) => {
          $.each(data, (key) => {
            if (key.indexOf('_') === 0) {
              // json comment is with prefix underscore like "_comment"
              delete data[key]
            }
          })
          this._callback(callback, data)
        }
      })
    }

    _getSlideConfig(slideName) {
      return this._data !== undefined ? this._data[slideName] : undefined
    }

    _hasSlideConfig(slideName) {
      return this._getSlideConfig(slideName) !== undefined
    }

    _callback(callback, ...args) {
      if (typeof callback === 'function') {
        callback.apply(this, args)
      }
    }

    _uniqueID() {
      return Math.floor(Math.random() * Date.now())
    }

    _isDeviceMobile() {
      try {
        document.createEvent('TouchEvent');
        return true;
      } catch (e) {
        return false;
      }
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new InteractiveForm(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.INTERACTIVE_FORM).each(function () {
      const $interactiveForm = $(this)
      InteractiveForm._jQueryInterface.call($interactiveForm, $interactiveForm.data())
    })
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = InteractiveForm._jQueryInterface
  $.fn[NAME].Constructor = InteractiveForm
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return InteractiveForm._jQueryInterface
  }

  return InteractiveForm

})($)

export default InteractiveForm
