import $ from 'jquery'
import Alert from '../../bootstrap/js/src/alert'
import Animation from './animation'
import BootstrapSelect from 'bootstrap-select'
import Button from '../../bootstrap/js/src/button'
import Carousel from './carousel'
import Collapse from '../../bootstrap/js/src/collapse'
import CompareImages from './compare-images'
import ContactForm from './contact-form'
import Dropdown from '../../bootstrap/js/src/dropdown'
import ImageHotspot from './image-hotspot'
import InteractiveForm from './interactive-form'
import LazyLoad from 'vanilla-lazyload'
import Modal from '../../bootstrap/js/src/modal'
import NavbarScrollTransition from './navbar-scroll-transition'
import Parallax from './parallax'
import Popover from '../../bootstrap/js/src/popover'
import Scroll from './scroll'
import Scrollspy from '../../bootstrap/js/src/scrollspy'
import Tab from '../../bootstrap/js/src/tab'
import Tooltip from '../../bootstrap/js/src/tooltip'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.0.0-alpha.6): index.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

(() => {
  if (typeof $ === 'undefined') {
    throw new Error('Bootstrap\'s JavaScript requires jQuery. jQuery must be included before Bootstrap\'s JavaScript.')
  }

  const version = $.fn.jquery.split(' ')[0].split('.')
  const minMajor = 1
  const ltMajor  = 2
  const minMinor = 9
  const minPatch = 1
  const maxMajor = 4

  if (version[0] < ltMajor && version[1] < minMinor || version[0] === minMajor && version[1] === minMinor && version[2] < minPatch || version[0] >= maxMajor) {
    throw new Error('Bootstrap\'s JavaScript requires at least jQuery v1.9.1 but less than v4.0.0')
  }

  /*
  window.addEventListener("load", function(event) {
    $('.selectpicker').selectpicker();
  });
  */
})($)

export {
  Util,
  Alert,
  Animation,
  BootstrapSelect,
  Button,
  Carousel,
  Collapse,
  CompareImages,
  ContactForm,
  Dropdown,
  ImageHotspot,
  InteractiveForm,
  LazyLoad,
  Modal,
  NavbarScrollTransition,
  Parallax,
  Popover,
  Scroll,
  Scrollspy,
  Tab,
  Tooltip
}
