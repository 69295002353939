import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): navbar-scroll-transition.js
 * --------------------------------------------------------------------------
 */

const NavbarScrollTransition = (($) => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'navbar-scroll-transition'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'onma.navbar-scroll-transition'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    scrollPosition: 30
  }

  const DefaultType = {
    scrollPosition: 'number'
  }

  const Selector = {
    NAVBAR_SCROLL_TRANSITION : '[data-plugin="navbar-scroll-transition"]'
  }

  const Event = {
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`,
    SCROLL : `scroll${EVENT_KEY}${DATA_API_KEY}`
  }

  /*
  const ClassName = {
  }
  */

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class NavbarScrollTransition {

    constructor(element, config) {
      this._element = element
      this._config  = this._getConfig(config)
      $(window).on(Event.SCROLL, () => {
        this._scrollY()
      })
      this._scrollY()
    }


    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private

    _scrollY() {
      const scrollY = window.scrollY;

      if (scrollY > this._config.scrollPosition && !this._element.classList.contains('navbar-scrolled')) {
        this._element.classList.add('navbar-scrolled')
      } else if (scrollY <= this._config.scrollPosition && this._element.classList.contains('navbar-scrolled')) {
        this._element.classList.remove('navbar-scrolled');
      }

      if (!this._element.classList.contains('navbar-scrolled-transition')) {
        this._element.classList.add('navbar-scrolled-transition');
      }
    }

    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data = $element.data(DATA_KEY)

        if (!data) {
          data = new NavbarScrollTransition(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.NAVBAR_SCROLL_TRANSITION).each(function () {
      const $navbar = $(this)
      NavbarScrollTransition._jQueryInterface.call($navbar, $navbar.data())
    })
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = NavbarScrollTransition._jQueryInterface
  $.fn[NAME].Constructor = NavbarScrollTransition
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return NavbarScrollTransition._jQueryInterface
  }

  return NavbarScrollTransition

})($)

export default NavbarScrollTransition
