import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): contactform.js
 * --------------------------------------------------------------------------
 */

const ContactForm = (($) => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'contactform'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'onma.contactform'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    type         : 'POST',
    dataType     : 'json',
    url          : 'mailer.php',
    recaptcha    : ''
  }

  const DefaultType = {
    type         : 'string',
    dataType     : 'string',
    url          : 'string',
    recaptcha    : 'string'
  }

  const Selector = {
    BODY                      : 'body',
    CONTACTFORM               : '[data-form="contact"]',
    ERROR_MESSAGE_CONTAINER   : '.error-message-container',
    SUCCESS_MESSAGE_CONTAINER : '.success-message-container'
  }

  const Event = {
    SCROLL          : `scroll${EVENT_KEY}`,
    LOAD_DATA_API   : `load${EVENT_KEY}${DATA_API_KEY}`,
    SUBMIT_DATA_API : `submit${EVENT_KEY}${DATA_API_KEY}`
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class ContactForm {

    constructor(element, config) {
      this._element = element
      this._config  = this._getConfig(config)

      this.errorMessageContainer = $(this._element).find(Selector.ERROR_MESSAGE_CONTAINER)
      this.successMessageContainer = $(this._element).find(Selector.SUCCESS_MESSAGE_CONTAINER)

      this.errorMessageContainer.hide()
      this.successMessageContainer.hide()
      console.log(this.errorMessageContainer, this.successMessageContainer)

      $(this._element).submit(Event.SUBMIT_DATA_API, (event) => {
        event.preventDefault()
        this._submitForm()

        return false
      })

      /* eslint-disable no-undef */
      if (this._config.recaptcha) {
        $(window).on(Event.SCROLL, () => {
          if ((typeof grecaptcha === 'undefined' || !grecaptcha) && this._inView()) {
            const $recaptcha  = $(`<script type="text/javascript" src="https://www.google.com/recaptcha/api.js?render=${this._config.recaptcha}" />`);
            $(Selector.BODY).append($recaptcha);
          }
        })
      }
    }

    // getters
    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _submitForm() {
      const form = this._element
      const formData = new FormData(form)

      /*
      if (typeof grecaptcha !== 'undefined' && grecaptcha) {
        grecaptcha.execute(this._config.recaptcha, {
          action: 'contact'
        }).then((token) => {
          formData.append('recaptcha', token)
        })
      }
      */

      this.errorMessageContainer.hide()
      this.successMessageContainer.hide()

      $.ajax({
        type: this._config.type,
        url: this._config.url,
        data: formData,
        dataType: this._config.dataType,
        processData: false,
        contentType: false,
        error: () => {
          this.errorMessageContainer.html('Fehler beim Senden der Anfrage. Bitte versuchen Sie es später nochmal.').show();
        },
        success: (data) => {
          if (data.status) {
            form.reset();
            /*
            if (typeof grecaptcha !== 'undefined' && grecaptcha) {
              grecaptcha.reset()
            }
            */
            this.successMessageContainer.html(data.message).show()
            if (typeof dataLayer !== 'undefined' && dataLayer) {
              dataLayer.push({
                event: 'contact_form_success'
              });
            }
          } else if (data.validation) {
            this.errorMessageContainer.html(data.message).show();
            $.each(data.errors, (name) => {
              $(form).find(`[name="${name}"]`).addClass('is-invalid');
            });
            if (typeof dataLayer !== 'undefined' && dataLayer) {
              dataLayer.push({
                event: 'contact_form_error'
              });
            }
          } else {
            this.errorMessageContainer.html(data.message).show();
            if (typeof dataLayer !== 'undefined' && dataLayer) {
              dataLayer.push({
                event: 'contact_form_error'
              });
            }
          }
        }
      })
      /* eslint-enable no-undef */
    }

    _inView() {
      const rect = this._element.getBoundingClientRect()

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    // static
    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new ContactForm(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */
  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.CONTACTFORM).each(function () {
      const $contactform = $(this)
      ContactForm._jQueryInterface.call($contactform, $contactform.data())
    })
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */
  $.fn[NAME]             = ContactForm._jQueryInterface
  $.fn[NAME].Constructor = ContactForm
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return ContactForm._jQueryInterface
  }

  return ContactForm

})($)

export default ContactForm
