import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): animation.js
 * --------------------------------------------------------------------------
 */

const Animation = (($) => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'animation'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'onma.animation'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    type: 'bounceInRight'
  }

  const DefaultType = {
    type: 'string'
  }

  const Selector = {
    ANIMATION : '[data-plugin="animation"]'
  }

  const Event = {
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`,
    SCROLL: `scroll${EVENT_KEY}${DATA_API_KEY}`,
    ANIMATION_END: `animateend${EVENT_KEY}${DATA_API_KEY}`
  }

  const ClassName = {
    ANIMATION_ANIMATED: 'animate__animated',
    HIDE: 'animate__animated--hide'
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Animation {

    constructor(element, config) {
      this._element  = element
      this._config   = this._getConfig(config)

      this._$element = $(element);
      this._$element.addClass(ClassName.ANIMATION_ANIMATED);
      this._$element.addClass(ClassName.HIDE);

      this._checkScroll();

      $(window).on(Event.SCROLL, () => {
        this._checkScroll()
      });

      this._$element.on(Event.ANIMATION_END, () => {
        this._$element.removeClass(ClassName.ANIMATION_ANIMATED);
        this._$element.removeClass(`animation__${this._config.type}`);
      });
    }


    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _checkScroll() {
      if (this._inView()) {
        this._$element.removeClass(ClassName.HIDE);
        this._$element.addClass(`${ClassName.SHOW} animate__${this._config.type}`);
      }
    }

    _inView() {
      /*
      const rect = this._element.getBoundingClientRect()

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )*/
      return window.pageYOffset + document.documentElement.clientHeight >= this._element.offsetTop;
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new Animation(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.ANIMATION).each(function () {
      const $animation = $(this)
      Animation._jQueryInterface.call($animation, $animation.data())
    })
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = Animation._jQueryInterface
  $.fn[NAME].Constructor = Animation
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Animation._jQueryInterface
  }

  return Animation

})($)

export default Animation
