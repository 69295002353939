import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): image-hotspot.js
 * --------------------------------------------------------------------------
 */

const ImageHotspot = (($) => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'image-hotspot'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'onma.image-hotspot'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {}

  const DefaultType = {}

  const Selector = {
    IMAGE_HOTSPOT : '[data-plugin="image-hotspot"]',
    HIGHLIGHT_SPOT: '.highlight-spot',
    HOTSPOT_CONTENT_WRAPPER: '.hotspot-content-wrapper',
    HOTSPOT_HEADLINE: '.hotspot-headline',
    HOTSPOT_CONTENT: '.hotspot-content'
  }

  const Event = {
    CLICK : `click${EVENT_KEY}${DATA_API_KEY}`,
    HOVER_ENTER : `mouseenter${EVENT_KEY}${DATA_API_KEY}`,
    HOVER_LEAVE : `mouseleave${EVENT_KEY}${DATA_API_KEY}`,
    TRANSITION_END: `transitionend${EVENT_KEY}${DATA_API_KEY}`,
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`
  }

  const ClassName = {
    SHOW: 'show',
    HIGHLIGHT_SPOT_ACTIVE: 'highlight-spot-active'
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class ImageHotspot {

    constructor(element, config) {
      this._element  = element
      this._config   = this._getConfig(config)
      this._$element = $(element)

      const $spots                 = this._$element.find(Selector.HIGHLIGHT_SPOT)
      const $hotspotContentWrapper = this._$element.find(Selector.HOTSPOT_CONTENT_WRAPPER)
      const $hotspotHeadline       = this._$element.find(Selector.HOTSPOT_HEADLINE)
      const $hotspotContent        = this._$element.find(Selector.HOTSPOT_CONTENT)

      this._defaultHotspotHeadline = $hotspotHeadline.html();
      this._defaultHotspotContent = $hotspotContent.html();

      $spots.each((i, spot) => {
        const $spot = $(spot)

        const x = $spot.data('x')
        const y = $spot.data('y')

        $spot.css({
          left: `${x}%`,
          top: `${y}%`
        })

        const headline = $spot.data('headline');
        const content = $spot.html();
        /*
        $spot.on(`${Event.HOVER_ENTER},${Event.CLICK}`, (event) => {
          event.preventDefault()
          $hotspotHeadline.html(headline)
          $hotspotContent.html(content)
          //$hotspotContentWrapper.addClass('show')
        }).on(Event.HOVER_LEAVE, (event) => {
          event.preventDefault()
          //$hotspotContentWrapper.one(Event.TRANSITION_END, () => {
          $hotspotHeadline.html(this._defaultHotspotHeadline)
          $hotspotContent.html(this._defaultHotspotContent)
          //}).removeClass('show')
        })
        */

        $spot.on(`${Event.CLICK}`, (event) => {
          event.preventDefault()
          $spots.removeClass(ClassName.HIGHLIGHT_SPOT_ACTIVE)
          $hotspotContentWrapper.addClass('active-point');
          $hotspotHeadline.html(headline)
          $hotspotContent.html(content)
          $spot.addClass(ClassName.HIGHLIGHT_SPOT_ACTIVE)
          // $hotspotContentWrapper.addClass('show')
        })

        $spot.addClass(ClassName.SHOW)
      })
    }


    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data = $element.data(DATA_KEY)

        if (!data) {
          data = new ImageHotspot(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.IMAGE_HOTSPOT).each(function () {
      const $imageHotspot = $(this)
      ImageHotspot._jQueryInterface.call($imageHotspot, $imageHotspot.data())
    })
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = ImageHotspot._jQueryInterface
  $.fn[NAME].Constructor = ImageHotspot
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return ImageHotspot._jQueryInterface
  }

  return ImageHotspot

})($)

export default ImageHotspot
